.counts-heading {
    margin-top: 20px;
}
.update-bud-counts-btn {
    margin-top: 10px;
}
.heading {
    font-weight: bold;
    width: 150px;
}
.value-cell {
    flex-grow: 1;
    width: 100%;
}
.card-row {
    border-bottom: 1px solid #ddd;
}
.add-count-input {
    width: 100%;
}

.add-bundle-input {
    width: 100%;
}