.page-spinner {
    position: absolute;
    right: 10px;
    top: 18px;
}

.error-ul {
    margin: 0;
}

.page-icon {
    margin-right: 10px;
}

.page-back-url {
    font-size: 12px;
}
