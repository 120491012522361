.loading-spinner {
    position: fixed;
}

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}

td a {
    display: block;
}

.search-table__pagination-per-page-container {
    padding: 10px 0px;
}

.search-table__pagination-per-page-container label {
    margin-right: 10px;
}

.search-table__pagination-per-page-container select {
    width: 100px;
}

.search-table__no-records {
    margin: 5px;
}

.search-table__ordering-cross {
    color: red;
    padding-left: 5px;
    cursor: pointer;
}

.search-table__search-container {
    padding-bottom: 20px;
}

.search-table__bootstrap-table .fas.fa-check {
    color: green;
}

.search-table__bootstrap-table .fas.fa-times {
    color: red;
}
