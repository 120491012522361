.nav-user-info {
    border-bottom: 3px solid #666;
    margin-bottom: 8px;
    font-size: 12px !important;
    text-align: center;
}

.large-sidebar-logo {
    margin-left: 35% !important;
}
